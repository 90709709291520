import React, { useRef, useState, useEffect, useContext } from "react";
import { IoSend } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import ChatContext from "../../../../../chatContext/ChatContext";
import { API_BASE_URL } from "../../../../../redux/config";
import axios from "axios";
import { useParams } from "react-router-dom";
import chatAdminUser from "../../../../../../src/common/utils/AdminData";
// import chatAdminUser from "./client/src/common/utils/AdminData";

const SendMsgInp = ({
  setGoHome,
  setRecomended,
  setShowLastMessage,
  setMyChat,
  setIsLoading,
  goHome,
  isDashboard,
}) => {
  const textareaRef = useRef(null);
  const [inputStr, setInputStr] = useState("");
  const {
    guestChatUsers,
    setguestChatData,
    setGuestChatUsers,
    setProfileUserData,
    setIsTyping,
    profileUserData,
    sideAllUsers,
  } = useContext(ChatContext);
  const params = useParams();

  const prevSenderId = useRef(null);
  const prevReceiverId = useRef(null);

  const updateMessagesAsRead = async (senderId, receiverId) => {
    try {
      await axios.put(
        `${API_BASE_URL}/api/messages/updatemsg/${senderId}/${receiverId}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  useEffect(() => {
    const senderId = params?.id || localStorage.getItem("adminid");
    const receiverId = localStorage.getItem("userid");

    // Check if the senderId or receiverId has changed
    if (
      senderId !== prevSenderId.current ||
      receiverId !== prevReceiverId.current
    ) {
      updateMessagesAsRead(senderId, receiverId);
      prevSenderId.current = senderId;
      prevReceiverId.current = receiverId;
    }
  }, [params, sideAllUsers]);

  const postMessage = async (data) => {
    try {
      const receiverEmailData = chatAdminUser.find(
        (user) => String(user.id) === String(data.receiverId)
      );
      const formData = new FormData();
      formData.append("text", data.text || "");
      formData.append("receiverEmail", receiverEmailData?.email || "");
      formData.append("senderName", localStorage.getItem("username") || "");
      formData.append("receiverId", data.receiverId || "");
      formData.append("senderId", data.senderId || "");
      formData.append("image", data.image || "");
      formData.append("replyId", data.replyId || "");

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${API_BASE_URL}/api/messages/message`,
        formData,
        config
      );
      const responseData = response?.data || [];
      setguestChatData((p) => [{ type: "sent", ...responseData }, ...p]);
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputStr]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputStr.trim().length < 1) return;
    const finaldata = {
      ...guestChatUsers,
      text: inputStr,
      receiverId: params?.id || localStorage.getItem("adminid"),
      senderId: localStorage.getItem("userid"),
    };
    if (
      Number(localStorage.getItem("userid")) &&
      Number(finaldata?.receiverId) > 0
    ) {
      postMessage(finaldata);
      setInputStr("");
      setIsTyping((p) => ({ status: false, receiverId: 0, senderId: 0 }));
    } else {
      setMyChat((p) => [
        ...p,
        {
          id: Math.random(),
          type: "sent",
          image: "",
          text: finaldata.text,
          date: new Date().toISOString(),
          subData: {},
        },
      ]);
      setIsLoading(true);
      setRecomended([]);
      if (!goHome) {
        setGoHome(true);
      }
      setTimeout(() => {
        setShowLastMessage(true);
        setIsLoading(false);
      }, 1001);
      setIsTyping((p) => ({ status: false, receiverId: 0, senderId: 0 }));
      setInputStr("");
      try {
      } catch (error) {}
    }
  };
  useEffect(() => {
    const userId = Number(localStorage.getItem("userid"));
    const adminId = Number(localStorage.getItem("adminid"));

    if (userId > 0 && adminId > 0) {
      setGoHome(true);
    }
  }, [setGoHome]);

  const homeBtnClick = () => {
    localStorage.removeItem("userid");
    localStorage.removeItem("adminid");
    setGuestChatUsers([]);
    setGoHome(false);
    setRecomended([]);
    setProfileUserData({});
    setShowLastMessage(false);
  };

  const handleChangeMsg = (e) => {
    setInputStr(e.target.value);
    setShowLastMessage(false);

    if (e.target.value.length > 0) {
      setIsTyping((p) => ({
        ...p,
        status: true,
        receiverId: profileUserData.id,
        senderId: localStorage.getItem("userid"),
      }));
    } else {
      setIsTyping((p) => ({ status: false, receiverId: 0, senderId: 0 }));
    }
  };

  return (
    <div
      className={`absolute ${
        isDashboard
          ? "bg-[#f0f8ff]"
          : " bg-gradient-to-b to-[#2B0282] from-[#0056A6]"
      } h-[65px] flex justify-center items-center z-50 right-0 left-0 web-wash border-t !bottom-0`}
    >
      <form className="w-full relative " onSubmit={handleSubmit}>
        <div
          className={`w-full flex justify-center items-center gap-3 px-2 sm:px-5`}
        >
          <FaHome
            onClick={homeBtnClick}
            className="text-2xl text-[#0056A6] hover:text-[#2B0282] duration-200 hover:scale-110 cursor-pointer absolute left-[1.3rem] bottom-[0.4rem] sm:left-9 z-40"
          />

          <div className="relative w-full z-20 h-full flex justify-center items-center">
            <input
              ref={textareaRef}
              type="text"
              name="message"
              id="message"
              placeholder="Your message..."
              className={` w-full flex justify-center items-center border rounded-full border-black/15 !bg-[#fbfcf8] pl-12 pr-4 !h-[35px] font-poppins text-[0.85rem] focus:outline-none text-sm focus:border-[#009dbd75] !text-gray-700`}
              value={inputStr}
              onChange={(e) => handleChangeMsg(e)}
              autoComplete="off"
            />
          </div>
          <button type="submit" className="outline-none">
            <IoSend
              className={`text-2xl scale-105 cursor-pointer ${
                isDashboard ? "text-[#2B0282]" : "text-[#fbfcf8]"
              }  hover:text-[#ff]`}
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendMsgInp;
