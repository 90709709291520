import { Avatar } from "@nextui-org/react";
import React from "react";
import { API_BASE_URL } from "../../../../../redux/config";
import DateConvertor from "../../../../../common/utils/DateConvertor";
import { FaAngleRight } from "react-icons/fa6";

const EachChat = ({
  chatProfile,
  message,
  shouldShowAvatar,
  setOpenUserLoginModal,
  profileUserData,
}) => {
  return (
    <>
      {message.id === -999 ? (
        <div className="w-full h-full flex justify-center items-center gap-2 mt-1 mb-5">
          <div className="w-full h-[1px] bg-black/20"></div>
          <div className="text-nowrap text-xs text-black/70">
            Start chat with{" "}
            <span className="capitalize">{profileUserData.name}</span>
          </div>
          <div className="w-full h-[1px] bg-black/20"></div>
        </div>
      ) : (
        <>
          {(message?.text?.trim().length > 0 || message?.image) && (
            <div
              className={`flex gap-1 md:gap-2 min-h-[30px] ${
                message?.type === "sent" ? "mb-2.5" : "mb-5"
              }  relative items-end w-full`}
            >
              {shouldShowAvatar && (
                <div className="rounded-full absolute left-0 flex justify-center items-end bg-red-300">
                  <Avatar
                    src={profileUserData?.image || chatProfile}
                    showFallback
                    size="sm"
                    classNames={{
                      icon: "text-black/50",
                    }}
                  />
                </div>
              )}
              <div
                className={`h-full ml-[40px] w-full flex flex-col ${
                  message?.type === "sent" ? "items-end" : "items-start"
                } justify-center-center group`}
              >
                {message?.image?.length > 5 && (
                  <div className="max-w-[50%] relative mb-1">
                    <img
                      radius="sm"
                      className={`${
                        message?.type === "sent" ? "float-right" : "float-left"
                      } w-full mb-1 z-10 shadow max-h-[250px]`}
                      src={API_BASE_URL + message?.image}
                      alt={message?.text || "message"}
                      loading="lazy"
                    />
                  </div>
                )}
                {message?.text.trim().length > 0 && (
                  <div
                    className={`font-poppins z-10 relative text-xs sm:text-sm flex max-w-[60%] flex-col items-center justify-center `}
                  >
                    <div
                      className={`shadow-sm !text-sm max-w-[350px] rounded-md px-3 py-1.5  ${
                        message?.type !== "sent"
                          ? "rounded-bl-none bg-[#fbfcf8] text-[#333333]"
                          : "rounded-br-none bg-gradient-to-b from-[#2B0282] to-[#0056A6] text-[#f0f8ff]"
                      }`}
                    >
                      {message?.text}
                      <div
                        className={`text-[0.53rem] text-black/50 absolute  ${
                          message?.type === "sent"
                            ? "right-0 -bottom-[1.03rem] hidden"
                            : "right-0 -bottom-[1.25rem]"
                        } `}
                      >
                        {DateConvertor(message.date.toString())}
                      </div>
                      {String(message?.id) === "999999999999" && (
                        <>
                          <div className="border-t my-1"></div>
                          <div
                            onClick={() => setOpenUserLoginModal((p) => !p)}
                            className="flex items-center text-[#2B0282] cursor-pointer hover:tracking-wide duration-150"
                          >
                            <span>Talk to a person</span>
                            <FaAngleRight className="scale-90 mt-[1px]" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EachChat;
