import { API_BASE_URL } from "../config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getfeedbacks = createAsyncThunk(
  "get/feedbacks",
  async (_, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/feedback/feedback`,
      config
    );
    return response.data;
  }
);
export const getUnreadMsg = createAsyncThunk(
  "get/unreadMsg",
  async (_, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/feedback/feedback/unread/msg`,
      config
    );
    return response.data;
  }
);

export const postfeedbacks = createAsyncThunk(
  "posts/feedbacks",
  async (post, thunkApi) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    const response = await axios.post(
      `${API_BASE_URL}/api/feedback/feedback`,
      post,
      config
    );
    return response.data;
  }
);

export const deletefeedbacks = createAsyncThunk(
  "delete/feedbacks",
  async (postId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(
      `${API_BASE_URL}/api/feedback/feedback/${postId}`,
      config
    );
    return postId;
  }
);

export const updateunreadcontent = createAsyncThunk(
  "update/feedbacks",
  async (id, thunkApi) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    const response = await axios.put(
      `${API_BASE_URL}/api/feedback/feedback/notify/${id}`,
      {},
      config
    );
    return response.data;
  }
);

const initialState = {
  feedbackData: [],
  loading: false,
  posts: [],
  unreadMsg: [],
};

const feedbacksSlice = createSlice({
  name: "feedbackss",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getfeedbacks.fulfilled, (state, action) => {
      state.loading = false;
      state.feedbackData = action.payload;
    });
    builder.addCase(getUnreadMsg.fulfilled, (state, action) => {
      state.loading = false;
      state.unreadMsg = action.payload.data;
    });

    builder.addCase(getfeedbacks.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(postfeedbacks.fulfilled, (state, action) => {
      state.posts.push(action.payload);
      state.feedbackData.push(action.payload.data);
    });

    builder.addCase(updateunreadcontent.fulfilled, (state, action) => {
      const updatedPost = action.payload.data;
      state.unreadMsg = state.unreadMsg.filter((e) => e.id !== updatedPost.id);
    });

    builder.addCase(deletefeedbacks.fulfilled, (state, action) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
      state.feedbackData = state.feedbackData.filter(
        (e) => e.id !== action.payload
      );
    });

    // builder.addCase(postfeedbacks.pending, (state, action) => {
    //   // Handle pending state for post creation if needed
    // });

    // builder.addCase(updatefeedbacks.pending, (state, action) => {
    //   // Handle pending state for post update if needed
    // });

    // builder.addCase(deletefeedbacks.pending, (state, action) => {
    //   // Handle pending state for post deletion if needed
    // });
  },
});

export const { increment } = feedbacksSlice.actions;
export default feedbacksSlice.reducer;
