import React, { useRef, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Listbox,
  ListboxItem,
  ListboxSection,
  Avatar,
} from "@nextui-org/react";
import Confirmlogout from "./ConFirmlogout.js";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { RiLogoutCircleRLine } from "react-icons/ri";

export default function Profile(props) {
  const { loginData } = props;
  const [isLogin, setIsLogin] = useState({ data: "", isLogged: false });
  const btnRef = useRef();
  const listBtnRef = useRef();
  const navigate = useNavigate();

  const logoutbtnClk = () => {
    btnRef.current.click();
    listBtnRef.current.click();
  };

  const goHomeClick = () => {
    listBtnRef.current.click();
    navigate("/");
  };

  return (
    <>
      <Popover placement="bottom" showArrow={true} className="!rounded-[3px]">
        <PopoverTrigger>
          <div className="scale-95">
            <Avatar
              className="shadow"
              as="button"
              src={loginData?.profile}
              showFallback
              classNames={{
                icon: "text-black/50",
              }}
              ref={listBtnRef}
            />
          </div>
        </PopoverTrigger>
        <PopoverContent className="!rounded-[3px]">
          <Listbox variant="faded" aria-label="Listbox menu with icons">
            <ListboxItem
              className="hover:!border-transparent p-0 m-0 cursor-text hover:!bg-transparent"
              textValue="Signed in as"
            >
              <div className="p-0 m-0">
                <div className="text-base font-semibold">Signed in as</div>
                <div className="line-clamp-1">
                  {loginData?.email ? loginData?.email : "user@example.com"}
                </div>
              </div>
            </ListboxItem>

            <ListboxSection title="Actions" className="border-b pb-1">
              <ListboxItem
                key="gohome"
                startContent={<IoMdHome className="text-xl" />}
                textValue="Go Home"
                onClick={goHomeClick}
              >
                <div className="!text-xs !font-semibold">Go Home</div>
              </ListboxItem>
            </ListboxSection>

            <ListboxSection title="Danger zone">
              <ListboxItem
                key="logout"
                className="text-danger"
                color="danger"
                description="Logout from browser"
                startContent={<RiLogoutCircleRLine className="text-xl" />}
                textValue="Logout"
                onClick={logoutbtnClk}
              >
                Logout
              </ListboxItem>
            </ListboxSection>
          </Listbox>
        </PopoverContent>
      </Popover>
      <Confirmlogout
        btnRef={btnRef}
        setIsLogin={setIsLogin}
        isLogin={isLogin}
      />
    </>
  );
}
