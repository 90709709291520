import React from "react";
import { LiaFacebookMessenger } from "react-icons/lia";

const NoUserSelected = () => {
  return (
    <div className="h-full w-full gap-2 font-poppins pb-5 flex justify-center items-center flex-col">
      <LiaFacebookMessenger className="text-[3rem] -mb-1" />
      <h2 className="text-xl font-semibold text-black/80">Your messages</h2>
      <h3 className="text-sm text-black/80">Send a message to start a chat.</h3>
    </div>
  );
};

export default NoUserSelected;
