import { API_BASE_URL } from "../config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let onProgressChangeCallback = null;
export const setOnProgressChangeCallback = (callback) => {
  onProgressChangeCallback = callback;
};
export let percentCompletedValue = 0;

export const getsubjects = createAsyncThunk("get/subject", async () => {
  const config = { headers: {} };
  const response = await axios.get(
    `${API_BASE_URL}/api/subject/subject`,
    config
  );
  return response.data;
});

export const postsubject = createAsyncThunk(
  "posts/subject",
  async (post, thunkApi) => {
    const data = { subject_name: post.subject_name, level: post.levelId, facultyid: post.facultyId }
    const config = {
      headers: {
        "Content-Type": "application/json",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    const response = await axios.post(
      `${API_BASE_URL}/api/subject/subject`,
      data,
      config
    );
    return response.data;
  }
);

export const updatesubject = createAsyncThunk(
  "update/subject",
  async (updatedPost, thunkApi) => {
    const data = { subject_name: updatedPost.subject_name, level: updatedPost.levelId, facultyid: updatedPost.facultyId }
    const config = {
      headers: {
        "Content-Type": "application/json",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    const response = await axios.put(
      `${API_BASE_URL}/api/subject/subject/${updatedPost.id}`,
      data,
      config
    );
    return response.data;
  }
);

export const deletesubject = createAsyncThunk(
  "delete/subject",
  async (postId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(
      `${API_BASE_URL}/api/subject/subject/${postId}`,
      config
    );
    return postId;
  }
);

const initialState = {
  subjectData: [],
  loading: false,
  posts: [],
};

const subjectSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getsubjects.fulfilled, (state, action) => {
      state.loading = false;
      state.subjectData = action.payload;
    });

    builder.addCase(getsubjects.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(postsubject.fulfilled, (state, action) => {
      state.posts.push(action.payload);
      state.subjectData.push(action.payload.data);
    });

    builder.addCase(updatesubject.fulfilled, (state, action) => {
      const updatedPost = action.payload.data;
      const index = state.subjectData.findIndex((subject) => subject.id === updatedPost.id);
      if (index !== -1) {
        state.subjectData[index] = updatedPost;
      } else {
        state.subjectData.push(updatedPost);
      }
    });
    
    builder.addCase(deletesubject.fulfilled, (state, action) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
      state.subjectData = state.subjectData.filter(
        (e) => e.id !== action.payload
      );
    });

    // builder.addCase(postsubject.pending, (state, action) => {
    //   // Handle pending state for post creation if needed
    // });

    // builder.addCase(updatesubject.pending, (state, action) => {
    //   // Handle pending state for post update if needed
    // });

    // builder.addCase(deletesubject.pending, (state, action) => {
    //   // Handle pending state for post deletion if needed
    // });
  },
});

export const { increment } = subjectSlice.actions;
export default subjectSlice.reducer;
