import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Progress,
  SelectItem,
  Textarea,
  Autocomplete,
  Input,
} from "@nextui-org/react";
import { IoMdLink } from "react-icons/io";
import { PlusIcon } from "../../../../common/components/Tables/Icons/PlusIcon.js";

const isAndData = [
  { id: 0, text: "Recomendation", value: 0 },
  { id: 1, text: "Answer", value: 1 },
];

export default function ModalApp(props) {
  const closeRef = useRef();
  const {
    handelPostchat,
    updateBtnRef,
    handelUpdate,
    updateData,
    setUpdateData,
    postUpload,
    ParentData = [],
  } = props;
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [chatData, setChatData] = useState({
    message: "",
    parentid: -1,
    isans: "0",
    msgurl: "",
    image: null,
  });
  const [validationErrors, setValidationErrors] = useState({
    message: "",
    parentid: "",
  });

  useEffect(() => {
    if (updateData.status) {
      setChatData({
        message: updateData.data.text || "",
        parentid: updateData.data.parentid || -1,
        isans: updateData.data.isans || "0",
        msgurl: updateData.data.url || "",
        image: updateData.data.image || null,
      });
    }
  }, [updateData]);

  const clearChatData = () => {
    setChatData({
      message: "",
      parentid: -1,
      isans: "0",
      msgurl: "",
      image: null,
    });
    setUpdateData({ status: false, data: {} });
    setValidationErrors({
      message: "",
      parentid: "",
    });
  };

  const chatDataChange = (e) => {
    const { name, value } = e.target;
    setChatData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setChatData((prevData) => ({ ...prevData, image: file }));
  };

  const handleParentChange = (value) => {
    if (value === "-1") {
      setChatData((prevData) => ({
        ...prevData,
        parentid: -1,
      }));
    } else {
      const selectedParent = ParentData.find(
        (item) => Number(item.id) === Number(value)
      );

      if (selectedParent) {
        setChatData((prevData) => ({
          ...prevData,
          parentid: selectedParent.id,
        }));
      }
    }
  };

  const handleIsAnsChange = (value) => {
    setChatData((prevData) => ({
      ...prevData,
      isans: value || "0",
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationErrors = {
      message: "",
      parentid: "",
    };

    if (!chatData.message) {
      newValidationErrors.message = "Message is required";
      isValid = false;
    }
    if (chatData.parentid === "") {
      newValidationErrors.parentid = "Parent is required";
      isValid = false;
    }

    setValidationErrors(newValidationErrors);

    return isValid;
  };

  const chatPost = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        message: chatData.message,
        parentid: chatData.parentid,
        isans: chatData.isans,
        msgurl: chatData.msgurl,
        image: chatData.image,
      };

      if (updateData.status) {
        data.id = updateData.data.id;
        handelUpdate(data);
      } else {
        handelPostchat(data);
      }
    }
  };

  useEffect(() => {
    if (postUpload === 100) {
      setTimeout(() => {
        closeRef?.current?.click();
      }, 1500);
    }
  }, [postUpload]);

  const [finalFilter, setFinalFilter] = useState([]);
  useEffect(() => {
    setFinalFilter([
      { id: -1, value: -1, text: "None", parentid: -1 },
      ...ParentData,
    ]);
  }, [ParentData]);

  return (
    <>
      <Button
        radius="sm"
        onPress={onOpen}
        color="primary"
        endContent={<PlusIcon />}
        ref={updateBtnRef}
        className="!hidden"
      >
        Add New
      </Button>
      <Modal
        isOpen={isOpen}
        radius="sm"
        size="xl"
        onOpenChange={onOpenChange}
        onClose={() => {
          clearChatData();
          onOpenChange();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <form
              method="post"
              onSubmit={chatPost}
              encType="multipart/form-data"
            >
              <ModalHeader className="flex flex-col gap-1 uppercase text-slate-600 dark:text-slate-200">
                {updateData.status ? "Update chat" : "Add chat"}
              </ModalHeader>
              <ModalBody>
                <div className="flex gap-2 -mb-2 items-center justify-between">
                  <Autocomplete
                    label="Parent Name"
                    placeholder="Select Parent name"
                    variant="underlined"
                    name="parentid"
                    value={chatData?.parentid?.toString()}
                    onSelectionChange={(value) => handleParentChange(value)}
                    defaultSelectedKey={String(updateData.data.parentid)}
                  >
                    {finalFilter.map((f, index) => (
                      <SelectItem key={f.id} value={f.id}>
                        {f.text}
                      </SelectItem>
                    ))}
                  </Autocomplete>

                  <Input
                    variant="underlined"
                    name="image"
                    size="sm"
                    onChange={handleImageChange}
                    accept="image/png, image/jpg, image/jpeg"
                    placeholder="Choose your image"
                    type="file"
                    label="Choose message image"
                  />
                </div>
                {validationErrors.message && (
                  <p className="text-xs text-danger">
                    {validationErrors.message}
                  </p>
                )}
                <div className="flex justify-center items-center gap-2">
                  <div className="w-1/2">
                    <Input
                      className="mt-1"
                      variant="underlined"
                      name="msgurl"
                      size="sm"
                      onChange={chatDataChange}
                      value={chatData.msgurl}
                      type="text"
                      label="Enter message URL"
                      endContent={<IoMdLink />}
                    />
                  </div>
                  <div className="w-1/2">
                    <Autocomplete
                      label="Message type"
                      placeholder="Message type"
                      variant="underlined"
                      className="mb-1"
                      name="isans"
                      value={chatData.isans.toString()}
                      onSelectionChange={(value) => handleIsAnsChange(value)}
                      defaultSelectedKey={
                        updateData.status
                          ? String(updateData?.data?.isans)
                          : String(isAndData[0].id)
                      }
                    >
                      {isAndData.map((e, index) => {
                        return (
                          <SelectItem key={e.id} value={e.value}>
                            {e.text}
                          </SelectItem>
                        );
                      })}
                    </Autocomplete>
                  </div>
                </div>
                <Textarea
                  label="Message"
                  placeholder="Enter your message"
                  variant="underlined"
                  name="message"
                  value={chatData.message}
                  onChange={chatDataChange}
                  minRows={3}
                />
                {validationErrors.message && (
                  <p className="text-xs -mt-1 text-danger">
                    {validationErrors.message}
                  </p>
                )}

                {postUpload > 0 && (
                  <Progress
                    aria-label="Uploading..."
                    size="sm"
                    value={postUpload}
                    className="w-full mt-1"
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <div className="flex gap-3 flex-row-reverse">
                  <Button
                    color="primary"
                    size="sm"
                    radius="none"
                    className="rounded-sm !uppercase ml-2 tracking-wide"
                    type="submit"
                  >
                    {updateData.status ? "Update" : "Post"}
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    variant="bordered"
                    onPress={onClose}
                    radius="none"
                    type="reset"
                    className="rounded-sm !uppercase tracking-wide"
                    ref={closeRef}
                    isDisabled={postUpload !== 0}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
