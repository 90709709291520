import React, { useContext, useEffect, useState } from "react";
import EachUser from "./EachUser";
import ChatContext from "../../../../../../chatContext/ChatContext";
// import UserContext from "../../../../../../../userContext/UserContext";

const AllUser = ({
  API_BASE_URL,
  isdashboard,
  userFilter,
  inpData,
  userDataAll,
}) => {
  const { onlineUsers } = useContext(ChatContext);

  const userData = {};
  const [msgUsers, setMsgUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    setMsgUsers(userDataAll);
  }, [userDataAll]);

  useEffect(() => {
    let finalData = msgUsers;

    if (userFilter.includes("admins")) {
      finalData = finalData.filter((e) => Number(e.userprivilege) > 0);
    }
    if (inpData.length > 2) {
      finalData = finalData.filter((e) =>
        e?.lastmsg?.toLowerCase()?.includes(inpData.toLowerCase())
      );
    }

    setFilteredUsers(finalData || []);
  }, [inpData, msgUsers, userFilter]);

  return (
    <div className="h-full pb-[149px] w-full overflow-auto mt-1">
      <div id="scrollableDivDashboard" className="h-full overflow-auto">
        {filteredUsers.length > 0 ? (
          <div className="px-3 flex gap-2 flex-col">
            {filteredUsers
              .sort((a, b) => new Date(b.lastmsgdate) - new Date(a.lastmsgdate))
              .map((e, index) => {
                let isSend = Number(e.senderid) === Number(userData?.data?.id);
                return (
                  <EachUser
                    key={index}
                    data={e}
                    API_BASE_URL={API_BASE_URL}
                    onlineUsers={onlineUsers}
                    isdashboard={isdashboard}
                    setMsgUsers={setMsgUsers}
                    isSend={isSend}
                  />
                );
              })}
          </div>
        ) : (
          <div className="px-3 flex gap-2 flex-col text-center min-h-[100px] justify-center items-center font-poppins text-sm font-semibold font-black/50">
            Users Not Found
          </div>
        )}
      </div>
    </div>
  );
};

export default AllUser;
