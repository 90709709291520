import React, { useEffect, useRef, useState } from "react";
import TablePage from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  postfeedbacks,
  getfeedbacks,
  deletefeedbacks,
  updateunreadcontent,
  getUnreadMsg,
} from "../../../../../redux/slices/feedbackSlice";

import { getUsers } from "../../../../../redux/slices/authSlice.js";
import { toast } from "sonner";

const Feedback = (props) => {
  const { MsgId } = props;
  const dispatch = useDispatch();
  const userRef = useRef(false);
  const [finalData, setFinalData] = useState([]);
  const { feedbackData } = useSelector((state) => state.feedbackReducer);
  const { allUserData } = useSelector((state) => state.authReducer);

  const joinedData = finalData.map((feedback) => {
    const userData = allUserData.find((user) => user.id === feedback.userId);

    // You may want to add a check to handle cases where no matching user is found
    if (userData) {
      return {
        ...feedback,
        user: userData, // You can include the user data in the result
      };
    }

    return feedback; // If no matching user is found, keep the original feedback data
  });

  useEffect(() => {
    if (userRef.current === false) {
      dispatch(getfeedbacks());
      dispatch(getUsers());
      dispatch(getUnreadMsg());
    }
    return () => {
      userRef.current = true;
    };
  }, [dispatch]);

  useEffect(() => {
    let finalCategoryData = feedbackData.map((item, index) => ({
      ...item,
      sn: index + 1,
    }));
    setFinalData(finalCategoryData);
  }, [feedbackData]);

  const handelpost = async (data) => {
    try {
      const response = await dispatch(postfeedbacks(data));
      if (response.payload?.success) {
        toast.success("Send message successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot send message");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelDelete = async (data) => {
    try {
      const response = await dispatch(deletefeedbacks(data));
      if (response.meta?.requestStatus === "fulfilled") {
        toast.warning("Message deleted successfully");
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot delete message");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const handelUpdate = async (data) => {
    try {
      await dispatch(updateunreadcontent(data));
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };
  return (
    <>
      <TablePage
        contentData={joinedData[0]?.sn ? joinedData : []}
        handelPost={handelpost}
        handelDelete={handelDelete}
        handelUpdate={handelUpdate}
        MsgId={MsgId}
      />
    </>
  );
};

export default Feedback;
