import React from "react";

const Dashboard = () => {
  return (
    <div>
      <div>This is in developing phase</div>
    </div>
  );
};

export default Dashboard;
