import { Avatar, Badge } from "@nextui-org/react";
import React, { useCallback, useContext, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import ChatContext from "../../../../../chatContext/ChatContext";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../../redux/config";
import chatAdminUser from "../../../../../common/utils/AdminData";

const UserProfile = ({ setOpenConfirmModal, Logo, isDashboard }) => {
  const handelCloseClick = () => {
    setOpenConfirmModal((p) => !p);
  };
  const userId = useParams().id;
  const { profileUserData, setProfileUserData, onlineUsers } =
    useContext(ChatContext);

  const fetchProfileData = useCallback(
    async (id) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/guestuser/user/${id}`
        );
        if (!response.ok) throw new Error("Failed to fetch");
        const data = await response.json();
        setProfileUserData((prevState) => ({
          ...prevState,
          name: data.name,
          id: data.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    [setProfileUserData]
  );

  useEffect(() => {
    if (Number(userId) > 0) {
      fetchProfileData(userId);
    } else {
      const adminId = localStorage.getItem("adminid");
      if (Number(adminId) > 0) {
        const data = chatAdminUser.filter(
          (e) => Number(e.id) === Number(adminId)
        );
        if (data[0]) {
          setProfileUserData((prevState) => ({
            ...prevState,
            name: data[0].name,
            image: data[0].profile,
            id: data[0].id,
          }));
        }
      }
    }
  }, [userId, fetchProfileData, setProfileUserData]);

  return (
    <div
      className={`max-h-[55px] ${
        isDashboard
          ? "bg-[#f0f8ff] !shadow"
          : "bg-gradient-to-b from-[#2B0282] to-[#0056A6]"
      }  flex justify-start items-center web-wash`}
    >
      <div
        className={`h-full w-full shadow flex justify-start items-center py-2 px-4 gap-2.5 ${
          isDashboard && "pl-1.5"
        }`}
      >
        <div className="pt-1.5">
          {onlineUsers.includes(String(profileUserData?.id)) ? (
            <Badge
              content=""
              color="success"
              shape="circle"
              placement="bottom-right"
              size="sm"
            >
              <Avatar
                radius="full"
                className="scale-100"
                src={profileUserData?.image || Logo}
                showFallback
                classNames={{
                  icon: "text-black/50",
                }}
              />
            </Badge>
          ) : (
            <div className="-mt-1.5">
              <Avatar
                radius="full"
                className="scale-100"
                src={profileUserData?.image || Logo}
                showFallback
                classNames={{
                  icon: "text-black/50",
                }}
              />
            </div>
          )}
        </div>

        <div className="h-full w-full flex justify-start items-center relative">
          <div className="font-poppins w-full">
            <div
              className={`${
                isDashboard ? "text-[#1d1d1d]" : "text-[#ffffff]"
              }  text-sm mt-[2px] font-semibold flex justify-between`}
            >
              <div className="w-[70%] line-clamp-1 pb-0.5 capitalize text-xl">
                {profileUserData?.name || "QPixelbot"}
              </div>
            </div>
            {/* <div className="-mt-[2px] ml-[2px] flex justify-between items-center line-clamp-1 text-black/70 text-[0.70rem]">
              <div className="max-w-[80%] line-clamp-1">{"user@gmail.com"}</div>
            </div> */}
          </div>
          {!isDashboard && (
            <div className="relative">
              <RxCross2
                className="scale-110 text-2xl text-white/90 cursor-pointer hover:text-white/60"
                onClick={handelCloseClick}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
