import React from "react";
import { NextUIProvider } from "@nextui-org/react";
import { BrowserRouter } from "react-router-dom";
import ChatState from "./chatContext/ChatState";
import { Toaster } from "sonner";
import Client from "./components/client/Client";
import Dashboard from "./components/dashboard/Dashboard";
import { Provider } from "react-redux";
import makeStore from "./redux/store";

const App = () => {
  return (
    <NextUIProvider>
      <Provider store={makeStore()}>
        <BrowserRouter>
          <ChatState>
            <Client />
            <Dashboard />
            <Toaster richColors position="top-right" closeButton />
          </ChatState>
        </BrowserRouter>
      </Provider>
    </NextUIProvider>
  );
};

export default App;
