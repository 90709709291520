import React from "react";
import { API_BASE_URL } from "../../../../../redux/config";
import SwiperContainer from "./SwiperContainer";

const ChatHome = ({ frontChatData, chatInfoCardClick, imageDataSent }) => {
  return (
    <>
      <div className="h-full relative  flex flex-col w-full   text-white">
        <div>
          <div className="w-full h-[100px] absolute bg-[#0056A6] z-[-1]"></div>
          <div className="p-5 py-4 flex flex-col gap-2 font-medium bg-[#0070bd] z-10 rounded-t-xl">
            <SwiperContainer />
            <p>Namaste, I am Your Virtual Assistant. How may I help you?</p>
          </div>
          <div className="bg-[#fbfcf8] custom-shape-divider-top-1723196455 mt-[-3px]">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
              fill="#0070bd"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
        <div className="h-full w-full bg-[#fbfcf8]  rounded-t-xl pb-2 ">
          <div className="grid grid-cols-[repeat(auto-fit,minmax(75px,1fr))] w-full justify-start !pt-3 p-5 gap-4">
            {frontChatData.map((e, index) => {
              return (
                <div key={index} className="h-[90px]">
                  <div
                    onClick={() => chatInfoCardClick(e)}
                    className="flex text-black hover:scale-105 group duration-75 cursor-pointer justify-center items-center flex-col"
                  >
                    <img
                      src={API_BASE_URL + e.image}
                      alt={e.text}
                      width={20}
                      height={20}
                      className="h-[65px] w-[65px] rounded-lg object-cover border border-[#0056A6] p-2"
                    />
                    <p className="text-xs font-medium line-clamp-1 z-50 bg-[#fbfcf8] group-hover:line-clamp-none w-[80%] capitalize text-center pt-1">
                      {e.text}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatHome;
