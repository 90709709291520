import React from "react";
import ChatContentChild from "./messageComponent/ChatContent";

const ChatContent = ({ setChatProfileClick, chatProfile }) => {
  return (
    <>
      <div className="h-[560px] z-[1000000000000000000000000000000000] w-[390px] bg-white fixed overflow-hidden right-5 rounded-md shadow-md bottom-5">
        <ChatContentChild
          chatProfile={chatProfile}
          setChatProfileClick={setChatProfileClick}
        />
      </div>
    </>
  );
};

export default ChatContent;
