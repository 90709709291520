import { API_BASE_URL } from "../config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let onProgressChangeCallback = null;
export const setOnProgressChangeCallback = (callback) => {
  onProgressChangeCallback = callback;
};
export let percentCompletedValue = 0;

export const getfacultys = createAsyncThunk("faculty/getAll", async () => {
  const response = await axios.get(`${API_BASE_URL}/api/faculty/faculty`);
  return response.data;
});

export const postfaculty = createAsyncThunk(
  "faculty/post",
  async (post, thunkApi) => {
    const finalData = {
      faculty_name: post.facultyName,
      display_order: post.displayOrder,
      active: post.active,
      is_sem: post.is_sem
    };

    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        const percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // You can dispatch an action here to update the progress if needed
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/faculty/faculty`, finalData, config);
      return response.data;
    } catch (error) {
      // You can handle errors here, for example, dispatch an action to store the error in Redux state
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const updatefaculty = createAsyncThunk(
  "faculty/update",
  async (updatedPost, thunkApi) => {

    const finaldata = { faculty_name: updatedPost.facultyName, display_order: updatedPost.displayOrder, active: updatedPost.active, is_sem: updatedPost.is_sem }

    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    const response = await axios.put(
      `${API_BASE_URL}/api/faculty/faculty/${updatedPost.id}`,
      finaldata,
      config
    );
    return response.data;
  }
);

export const deletefaculty = createAsyncThunk(
  "faculty/delete",
  async (postId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(`${API_BASE_URL}/api/faculty/faculty/${postId}`, config);
    return postId;
  }
);

const initialState = {
  data: [],
  loading: false,
  posts: [],
};

const facultySlice = createSlice({
  name: "faculties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getfacultys.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getfacultys.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postfaculty.fulfilled, (state, action) => {
        state.posts.push(action.payload.data);
        state.data.push(action.payload.data);
      })
      .addCase(updatefaculty.fulfilled, (state, action) => {
        const updatedFaculty = action.payload.data;
        state.data = state.data.map(faculty =>
          faculty.id === updatedFaculty.id ? updatedFaculty : faculty
        );
      })
      .addCase(deletefaculty.fulfilled, (state, action) => {
        state.posts = state.posts.filter(post => post.id !== action.payload);
        state.data = state.data.filter(faculty => faculty.id !== action.payload);
      });
  },
});

export const { increment } = facultySlice.actions;
export default facultySlice.reducer;
