import React from "react";
import Chat from "./Chat";

const Message = ({ hamClick }) => {
  return (
    <div>
      <Chat hamClick={hamClick} />
    </div>
  );
};

export default Message;
