import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import Image1 from "../../../../../common/assets/images/img1.jpg";
import Image2 from "../../../../../common/assets/images/img2.jpg";
import Image3 from "../../../../../common/assets/images/img3.jpg";

import {
  EffectCreative,
  Autoplay,
  Pagination,
  Navigation,
} from "swiper/modules";

const SwiperContainer = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;

      swiperRef.current.navigation.destroy();
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, []);

  return (
    <div className="mb-1">
      <div className="relative">
        <Swiper
          ref={swiperRef} // Attach the swiper instance to the ref
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          grabCursor={true}
          modules={[EffectCreative, Autoplay, Pagination, Navigation]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          // pagination={{
          //   clickable: true,
          //   bulletClass: "swiper-pagination-bullet",
          //   bulletActiveClass: "swiper-pagination-bullet-active",
          // }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          loop={true}
          className="mySwiper"
        >
          <SwiperSlide>
            <img
              src={Image1}
              alt="img1"
              className="w-full h-full object-cover rounded-md"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Image2}
              alt="img2"
              className="w-full h-full object-cover rounded-md"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Image3}
              alt="img3"
              className="w-full h-full object-cover rounded-md"
            />
          </SwiperSlide>
        </Swiper>

        <div className="absolute top-1/2 transform -translate-y-1/2 -left-[15px] z-10">
          <button ref={prevRef} className="swiper-button-prev-custom">
            <FaAngleLeft className="text-[#F2F2F2]" />
          </button>
        </div>
        <div className="absolute top-1/2 transform -translate-y-1/2 -right-[15px] z-10">
          <button ref={nextRef} className="swiper-button-next-custom">
            <FaAngleRight className="text-[#F2F2F2]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SwiperContainer;
