import { API_BASE_URL } from "../config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let onProgressChangeCallback = null;
export const setOnProgressChangeCallback = (callback) => {
  onProgressChangeCallback = callback;
};
export let percentCompletedValue = 0;

export const getnews = createAsyncThunk("news/getAll", async () => {
  const response = await axios.get(`${API_BASE_URL}/api/news/news`);
  return response.data;
});

export const getSinglenews = createAsyncThunk("news/getSingle", async (id) => {
  const response = await axios.get(`${API_BASE_URL}/api/post/blog/${id}`);
  return response.data;
});

export const postnews = createAsyncThunk(
  "news/post",
  async (newsDataForm, thunkApi) => {
    const formData = new FormData();
    formData.append("description", newsDataForm.description);
    formData.append("facultyid", newsDataForm.facultyId);
    for (var j = 0; j < newsDataForm.images.length; j++) {
      formData.append("image", newsDataForm.images[j]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    const response = await axios.post(
      `${API_BASE_URL}/api/news/news`,
      formData,
      config
    );
    return response.data;
  }
);

export const updatenews = createAsyncThunk(
  "news/update",
  async (newsDataForm, thunkApi) => {
    const formData = new FormData();
    formData.append("description", newsDataForm.description);
    formData.append("facultyid", newsDataForm.facultyId);
    for (var j = 0; j < newsDataForm.images.length; j++) {
      formData.append("image", newsDataForm.images[j]);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    const response = await axios.put(
      `${API_BASE_URL}/api/news/news/${newsDataForm.id}`,
      formData,
      config
    );

    return response.data;
  }
);

export const deletenews = createAsyncThunk(
  "news/delete",
  async (postId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(`${API_BASE_URL}/api/news/news/${postId}`, config);
    return postId;
  }
);

const initialState = {
  newsData: [],
  loading: false,
  posts: [],
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getnews.fulfilled, (state, action) => {
        state.loading = false;
        state.newsData = action.payload.data;
      })
      .addCase(getnews.pending, (state) => {
        state.loading = true;
      })
      .addCase(postnews.fulfilled, (state, action) => {
        state.posts.push(action.payload.data);
        state.newsData.push(action.payload.data);
      })
      .addCase(updatenews.fulfilled, (state, action) => {
        const updatedPost = action.payload.data;
        const index = state.newsData.findIndex(
          (news) => news.id === updatedPost.id
        );
        if (index !== -1) {
          state.newsData[index] = updatedPost;
        } else {
          state.newsData.push(updatedPost);
        }
      })
      .addCase(deletenews.fulfilled, (state, action) => {
        state.posts = state.posts.filter((post) => post.id !== action.payload);
        state.newsData = state.newsData.filter(
          (news) => news.id !== action.payload
        );
      });
  },
});

export default newsSlice.reducer;
