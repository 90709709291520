import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../config";

// Create async thunks
// !======================== for users and material one month/all time data ======================
export const getAllUserAndMaterial = createAsyncThunk(
  "users/getAllUserAndMaterial",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/material/material-count`,
      config
    );
    return response.data;
  }
);

// !======================== for 6 month users  ======================
export const getSixMonthUsers = createAsyncThunk(
  "users/getSixMonthUsers",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/stats/login-data`,
      config
    );
    return response.data;
  }
);

// !======================== for get all admin  ======================
export const getAllAdmins = createAsyncThunk(
  "orders/getAllAdmins",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    const response = await axios.get(
      `${API_BASE_URL}/api/stats/user/adminuser`,
      config
    );
    return response.data;
  }
);

// !======================= old six month material ==========================
export const getSixMonthMaterial = createAsyncThunk(
  "orders/getSixMonthMaterial",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/material/material-data`,
      config
    );
    return response.data;
  }
);

// !============ get six month material post user and admin================
export const getMaterialPostUserAndAdmin = createAsyncThunk(
  "revenue/getMaterialPostUserAndAdmin",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/user/material-post`,
      config
    );
    return response.data;
  }
);
// !======================== for oneuser data ======================
export const getOneUserData = createAsyncThunk(
  "revenue/getOneUserData",
  async (id = 1) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/user/note-postuser/${id}`,
      config
    );
    return response.data;
  }
);
// !======================== for one user material ======================
export const getOneUserMaterial = createAsyncThunk(
  "delivery/getOneUserMaterial",
  async (id) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/stats/user/usermaterial/data/${id}`,
      config
    );
    return response.data;
  }
);

// !======================= old data ==========================
export const getOneMonthDelivery = createAsyncThunk(
  "delivery/getOneMonthDelivery",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/statistics/total-delivery/thismonth`,
      config
    );
    return response.data;
  }
);

// !====================== six month data ============================
export const getSixMonthDataChart = createAsyncThunk(
  "delivery/getSixMonthDelivery",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/statistics/monthly-revenue`,
      config
    );
    return response.data;
  }
);
export const getSixMonthDataChartThreeDiff = createAsyncThunk(
  "delivery/getSixMonthDeliveryThreeDiff",
  async () => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/statistics/monthly-data/barchart`,
      config
    );
    return response.data;
  }
);
export const getSixMonthDataChartTwoDiffUser = createAsyncThunk(
  "delivery/getSixMonthDeliveryTwodiffuser",
  async (id) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/statistics/monthly-data/barchart/${id}`,
      config
    );
    return response.data;
  }
);

// Define initial state
const initialState = {
  allUsersAndMaterial: {},
  sixMonthUsers: [],
  allAdmins: [],
  sixMonthMaterial: [],
  materialPostUserAndAdmin: [],
  oneUserData: {},
  oneUserMaterial: [],

  oneWeekRevenue: 0,
  todayRevenue: 0,
  oneMonthDelivery: 0,
  sixMonthData: [],
  sixMonthDataThreeDiff: [],
  sixMonthDataTwoDiffUser: [],
};

// Create a slice
const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // !============ All users and material ================
    builder.addCase(getAllUserAndMaterial.fulfilled, (state, action) => {
      state.loading = false;
      state.allUsersAndMaterial = action.payload;
    });

    // !============ get six month users ================
    builder.addCase(getSixMonthUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.sixMonthUsers = action.payload;
    });
    // !============ get all admins ================
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      state.loading = false;
      state.allAdmins = action.payload;
    });
    // !============ get six month material ================
    builder.addCase(getSixMonthMaterial.fulfilled, (state, action) => {
      state.loading = false;
      state.sixMonthMaterial = action.payload;
    });

    // !============ get six month material post user and admin================
    builder.addCase(getMaterialPostUserAndAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.materialPostUserAndAdmin = action.payload;
    });
    // !============ get one user data ================
    builder.addCase(getOneUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.oneUserData = action.payload;
    });
    // !============ Get one user material ================
    builder.addCase(getOneUserMaterial.fulfilled, (state, action) => {
      state.loading = false;
      state.oneUserMaterial = action.payload;
    });

    // !=============== old data ======================
    builder.addCase(getOneMonthDelivery.fulfilled, (state, action) => {
      state.loading = false;
      state.oneMonthDelivery = action.payload.totalDelivery;
    });
    // !============ six month data ================
    builder.addCase(getSixMonthDataChart.fulfilled, (state, action) => {
      state.loading = false;
      state.sixMonthData = action.payload;
    });
    builder.addCase(
      getSixMonthDataChartThreeDiff.fulfilled,
      (state, action) => {
        state.loading = false;
        state.sixMonthDataThreeDiff = action.payload;
      }
    );
    builder.addCase(
      getSixMonthDataChartTwoDiffUser.fulfilled,
      (state, action) => {
        state.loading = false;
        state.sixMonthDataTwoDiffUser = action.payload;
      }
    );
  },
});

// Export actions and reducer
export const { increment } = statisticsSlice.actions;
export default statisticsSlice.reducer;
