import React, { useContext, useState } from "react";
import { IoSearch } from "react-icons/io5";
import AllUser from "./AllUser";
import ChatContext from "../../../../../../chatContext/ChatContext";

const Users = ({ isdashboard }) => {
  const [userFilter, setUserFilter] = useState("all");
  const [inpData, setInpData] = useState("");

  const { sideAllUsers } = useContext(ChatContext);

  return (
    <div className="h-full md:!w-[320px] sm:!max-w-[280px] web-wash z-[20] overflow-hidden w-full sm:block sm:shadow sm:border-r bg-transparent absolute right-0 left-0 top-0 pt-[50px] sm:pt-0 bottom-0 sm:static">
      <div className="flex h-[140px] flex-col gap-4 py-4 px-4">
        <div className="flex w-full items-center justify-between">
          <div className="font-poppins text-xl font-bold ">Chats</div>
        </div>
        <div className="font-poppins  flex items-center gap-4 text-xs text-black/70 font-medium uppercase">
          <div
            onClick={() => setUserFilter("all")}
            className={`flex relative flex-col hover:text-black ${
              userFilter === "all" && "text-black"
            } cursor-pointer items-start`}
          >
            <span>All</span>{" "}
            {userFilter === "all" && (
              <div className="h-[3px] w-[10px] bottom-[-3px] absolute left-0 rounded-r-full bg-black"></div>
            )}
          </div>
          <div
            onClick={() => setUserFilter("admins")}
            className={`flex relative flex-col hover:text-black ${
              userFilter === "admins" && "text-black"
            }  cursor-pointer items-start`}
          >
            <span>Admins</span>
            {userFilter === "admins" && (
              <div className="h-[3px] w-[10px] bottom-[-3px] absolute left-0 rounded-r-full bg-black"></div>
            )}
          </div>
          <div
            onClick={() => setUserFilter("users")}
            className={`flex relative flex-col hover:text-black ${
              userFilter === "users" && "text-black"
            }  cursor-pointer items-start`}
          >
            <span>Users</span>
            {userFilter === "users" && (
              <div className="h-[3px] w-[10px] bottom-[-3px] absolute left-0 rounded-r-full bg-black"></div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-4 relative !bg-[#E8F0FE]  h-[33px] outline-none !rounded-full shadowdashboard overflow-hidden font-poppins text-xs text-black/80 ">
          <IoSearch className="z-40 absolute left-3 scale-85 text-lg" />
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Search chat..."
            className="absolute inset-0 py-1 px-2 outline-none !bg-[#E8F0FE] pl-8 pr-5 !text-[0.8rem]"
            value={inpData}
            onChange={(e) => setInpData(e.target.value)}
          />
        </div>
      </div>
      <div className="h-full w-full z-40 ">
        <AllUser
          userDataAll={sideAllUsers}
          API_BASE_URL={""}
          isdashboard={isdashboard}
          userFilter={userFilter}
          inpData={inpData}
        />
      </div>
      {/* <RequestMessage btnClkRef={btnClkRef} /> */}
    </div>
  );
};

export default Users;
