import React from "react";
import SideNav from "./SideNav";
import TopNavContent from "./TopNavContent";
import { useLocation } from "react-router-dom";

const TopNav = (props) => {
  const { setHamClick, hamClick } = props;
  const pathname = useLocation().pathname;
  const isDashboard = pathname.includes("dashboard");

  return isDashboard ? (
    <nav className="w-full overflow-auto h-[55px] ">
      <div className="w-full h-[55px] flex shadow dark:shadow-slate-600 bg-white dark:bg-[#121212] z-50 fixed top-0 left-0 right-0">
        <TopNavContent
          loginData={{}}
          setHamClick={setHamClick}
          hamClick={hamClick}
          unreadMsg={[]}
        />
      </div>
      <SideNav hamClick={hamClick} />
    </nav>
  ) : null;
};

export default TopNav;
