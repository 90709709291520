import axios from "axios";
import { API_BASE_URL } from "../redux/config";

const FetchMsgSideUsers = async (data) => {
  try {
    const config = {};
    const response = await axios.post(
      `${API_BASE_URL}/api/messages/lastmessagedetail`,
      data,
      config
    );
    return response?.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export default FetchMsgSideUsers;
