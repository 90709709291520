import React, { useEffect, useState } from "react";
import TopNav from "./common/navigation/TopNav";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DashboardPg from "./pages/main/dashboard/Dashboard";
import Addchat from "./pages/main/addchat/Addchat";
import Feedback from "./pages/interactive/feedback/Feedback";
import Message from "./pages/interactive/message.js/Message";

const Dashboard = () => {
  const [hamClick, setHamClick] = useState(
    JSON.parse(localStorage.getItem("isDashboardHamClose"))
  );
  const path = useLocation().pathname;
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("email")) {
      if (path.toLocaleLowerCase().includes("/dashboard")) {
        navigate("/");
      }
    }
    // eslint-disable-next-line
  }, [localStorage.getItem("email"), navigate, path]);
  if (!path.toLocaleLowerCase().includes("/dashboard")) return;
  return (
    <div>
      <TopNav setHamClick={setHamClick} hamClick={hamClick} />
      <div
        className={`${
          hamClick ? "ml-[50px]" : "ml-[180px]"
        } duration-200 py-3 px-5 bg-white !text-black`}
      >
        <Routes>
          <Route element={<DashboardPg />} path="/dashboard" />
          <Route element={<Addchat />} path="/dashboard/addchatcontent" />
          <Route element={<Feedback />} path="/dashboard/feedback" />
          <Route
            element={<Message hamClick={hamClick} />}
            path="/dashboard/message"
          />
          <Route
            element={<Message hamClick={hamClick} />}
            path="/dashboard/message/uid/:id"
          />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
