import React, { useContext, useEffect, useState } from "react";
import UserProfile from "./Top/UserProfile";
import Messages from "./Center/Messages";
import SendMsgInp from "./Bottom/MessageInput";
import ChatContext from "../../../../chatContext/ChatContext";
import Modal from "./Modal";
import ChatHome from "./chatHome/ChatHome";
import FeedbackModal from "./FeedbackModal";
import UserLoginModal from "./userLoginModal";

const ChatContentChild = ({
  loading,
  lastMsg,
  setChatProfileClick,
  chatProfile,
  isDashboard,
}) => {
  const {
    frontChatData,
    myChat,
    setMyChat,
    setRecomended,
    recomended,
    isLoading,
    setIsLoading,
    lastMessage,
    setShowLastMessage,
    showLastMessage,
    getRecomendedData,
    getAnswerData,
    guestChatData,
    paramPage,
  } = useContext(ChatContext);
  const [goHome, setGoHome] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [openUserLoginModal, setOpenUserLoginModal] = useState(false);

  const chatInfoCardClick = async (e) => {
    if (e?.url?.toLowerCase() === "feedback") {
      setOpenFeedbackModal(true);
      return;
    }
    setIsLoading(true);
    setGoHome(true);
    setMyChat((p) => [
      ...p,
      {
        ...e,
        type: "sent",
        image: "",
        date: new Date().toISOString(),
        subData: {},
      },
    ]);
    const delay = new Promise((resolve) => setTimeout(resolve, 500));
    const ansDataPromise = getAnswerData(e?.id);
    const recomendedDataPromise = getRecomendedData(e?.id);
    const [ansData] = await Promise.all([ansDataPromise, delay]);
    if (e?.text) {
      setMyChat((p) => [
        { ...ansData, type: "received", date: new Date().toISOString() },
        ...p,
      ]);
      if (!ansData?.id) {
        setShowLastMessage(true);
      }
    } else {
      setShowLastMessage(true);
    }
    try {
      const [data] = await Promise.all([recomendedDataPromise, delay]);
      setRecomended(data || []);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const [filteredGuestChatData, setFilteredGuestChatData] = useState([]);
  useEffect(() => {
    if (Number(paramPage) > 0) {
      const filter = guestChatData.filter((e) =>
        JSON.parse(e.chatusers).includes(String(paramPage))
      );
      setFilteredGuestChatData(filter);
    } else {
      setFilteredGuestChatData(guestChatData);
    }
  }, [paramPage, guestChatData]);

  return (
    <div className="h-full w-full relative">
      <UserProfile
        isDashboard={isDashboard}
        chatProfile={chatProfile}
        setOpenConfirmModal={setOpenConfirmModal}
        setChatProfileClick={setChatProfileClick}
        Logo={chatProfile}
      />
      {openConfirmModal && (
        <Modal
          isDashboard={isDashboard}
          setChatProfileClick={setChatProfileClick}
          setOpenConfirmModal={setOpenConfirmModal}
        />
      )}
      <div
        className={`w-full ${
          isDashboard ? "h-[calc(100%_-_120px)] !mb-3" : "h-[80%]"
        }  relative`}
      >
        {goHome || isDashboard ? (
          <>
            <div className="z-0 relative h-full w-full">
              <Messages
                isDashboard={isDashboard}
                data={
                  Number(localStorage.getItem("userid")) > 0
                    ? filteredGuestChatData
                    : myChat
                }
                chatProfile={chatProfile}
                setOpenConfirmModal={setOpenConfirmModal}
                setChatProfileClick={setChatProfileClick}
                loading={loading}
                lastMsg={lastMsg}
                recomended={recomended}
                setMyChat={setMyChat}
                setRecomended={setRecomended}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                lastMessage={lastMessage}
                setShowLastMessage={setShowLastMessage}
                showLastMessage={showLastMessage}
                getRecomendedData={getRecomendedData}
                getAnswerData={getAnswerData}
                setOpenUserLoginModal={setOpenUserLoginModal}
              />
            </div>
          </>
        ) : (
          <div className="z-0 relative h-full w-full">
            <div
              id="scrollableDiv"
              className="h-full relative z-10 flex flex-col overflow-x-hidden overflow-auto"
            >
              <ChatHome
                frontChatData={frontChatData}
                chatInfoCardClick={chatInfoCardClick}
              />
            </div>
          </div>
        )}
      </div>
      <SendMsgInp
        setGoHome={setGoHome}
        setRecomended={setRecomended}
        setShowLastMessage={setShowLastMessage}
        setMyChat={setMyChat}
        setIsLoading={setIsLoading}
        goHome={goHome}
        isDashboard={isDashboard}
      />
      {openFeedbackModal && (
        <FeedbackModal setOpenFeedbackModal={setOpenFeedbackModal} />
      )}
      {openUserLoginModal && (
        <UserLoginModal setOpenUserLoginModal={setOpenUserLoginModal} />
      )}
    </div>
  );
};

export default ChatContentChild;
