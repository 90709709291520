import React, { useContext, useEffect, useState } from "react";
import { Avatar, Badge } from "@nextui-org/react";
import ChatContent from "./ChatContent";
import Logo from "../../../common/assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../../redux/config";
import ChatContext from "../../../chatContext/ChatContext";
import axios from "axios";

const ChatProfile = () => {
  const [chatProfileClick, setChatProfileClick] = useState(false);
  const { renderData, setRenderData } = useContext(ChatContext);
  const location = useLocation().pathname;

  const [unreadMsg, setUnreadMsg] = useState(0);

  useEffect(() => {
    const chatUserId = localStorage.getItem("adminid");
    const userId = localStorage.getItem("userid");

    if (chatUserId && userId) {
      const data = { chatUserId, userId };

      fetch(`${API_BASE_URL}/api/messages/oneunread`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => setUnreadMsg(data?.unreadmsg || 0))
        .catch((error) => console.error("Error fetching chat data:", error));
    }
  }, [renderData]);

  const updateMessagesAsRead = async (senderId, receiverId) => {
    try {
      await axios.put(
        `${API_BASE_URL}/api/messages/updatemsg/${senderId}/${receiverId}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setRenderData((p) => !p);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  const profileHandelClick = () => {
    const receiverId = localStorage.getItem("adminid");
    const senderId = localStorage.getItem("userid");
    if (receiverId && senderId) {
      updateMessagesAsRead(senderId, receiverId);
    }
    setChatProfileClick(true);
  };

  return (
    <div>
      {!chatProfileClick ? (
        <div
          className={`fixed bottom-20 z-[1000000000000000000000000000000000] right-16 ${
            location.includes("dashboard") ? "hidden" : "block"
          }`}
          onClick={profileHandelClick}
        >
          <Badge
            content={unreadMsg > 9 ? "9+" : unreadMsg}
            className={`mt-1 ml-1 ${
              unreadMsg < 1
                ? "opacity-0 select-none"
                : "opacity-100 select-text"
            }`}
            color="primary"
            placement="top-left"
          >
            <Avatar
              // isBordered
              size="lg"
              color="primary"
              src={Logo}
              className="cursor-pointer"
            />
          </Badge>
        </div>
      ) : (
        <ChatContent
          chatProfile={Logo}
          setChatProfileClick={setChatProfileClick}
        />
      )}
    </div>
  );
};

export default ChatProfile;
