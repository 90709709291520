// messageSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../config";

let onProgressChangeCallback = null;
export const setOnProgressChangeCallback = (callback) => {
  onProgressChangeCallback = callback;
};
export let percentCompletedValue = 0;

// Async thunks for chat operations
export const fetchMessages = createAsyncThunk(
  "chat/fetchMessages",
  async (userId) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/messages/message/${userId}`,
      config
    );
    return response.data;
  }
);

export const postMessage = createAsyncThunk(
  "chat/postMessage",
  async (message, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        const percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/messages/message`,
        message,
        config
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "chat/deleteMessage",
  async (messageId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(
      `${API_BASE_URL}/api/messages/message/${messageId}`,
      config
    );
    return messageId;
  }
);

// Async thunk for fetching user image
export const fetchUserImage = createAsyncThunk(
  "chat/fetchUserImage",
  async (userId) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };
    const response = await axios.get(
      `${API_BASE_URL}/api/messages/userimage/${userId}`,
      config
    );
    return response.data;
  }
);

// Async thunk for fetching single user
export const fetchSingleUser = createAsyncThunk(
  "chat/fetchSingleUser",
  async (id) => {
    const response = await axios.get(`${API_BASE_URL}/api/user/user/${id}`);
    return response.data;
  }
);

// Initial state for the chat slice
const initialState = {
  messages: [],
  totalMessage: 0,
  currentPage: 1,
  userImage: null,
  singleUser: null,
  loading: false,
  error: null,
};

// Slice to manage chat state
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload.messages;
        state.totalMessage = action.payload.totalMessages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postMessage.fulfilled, (state, action) => {
        state.messages.push(action.payload);
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.messages = state.messages.filter(
          (message) => message.id !== action.payload
        );
      })
      .addCase(fetchUserImage.fulfilled, (state, action) => {
        state.userImage = action.payload;
      })
      .addCase(fetchSingleUser.fulfilled, (state, action) => {
        state.singleUser = action.payload;
      });
  },
});

export default chatSlice.reducer;
