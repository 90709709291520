import { API_BASE_URL } from "../config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let onProgressChangeCallback = null;
export const setOnProgressChangeCallback = (callback) => {
  onProgressChangeCallback = callback;
};
export let percentCompletedValue = 0;

export const getnotes = createAsyncThunk("notes/getAll", async () => {
  const response = await axios.get(`${API_BASE_URL}/api/material/material`);
  return response.data;
});

export const getSinglenotes = createAsyncThunk(
  "notes/getSingle",
  async (id) => {
    const response = await axios.get(
      `${API_BASE_URL}/api/material/material${id}`
    );
    return response.data;
  }
);

export const postnotes = createAsyncThunk(
  "notes/post",
  async (notesData, thunkApi) => {
    const formData = new FormData();
    formData.append("description", notesData.description);
    formData.append("name", notesData.name);
    formData.append("facultyid", notesData.facultyId);
    formData.append("level", notesData.level);
    formData.append("subjectid", notesData.subjectId);
    formData.append("is_request", notesData.is_request);
    formData.append("material_type", notesData.materialType);
    formData.append("file", notesData.file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    const response = await axios.post(
      `${API_BASE_URL}/api/material/material`,
      formData,
      config
    );
    return response.data;
  }
);

export const updatenotes = createAsyncThunk(
  "notes/update",
  async (notesData, thunkApi) => {
    const formData = new FormData();
    formData.append("description", notesData.description);
    formData.append("name", notesData.name);
    formData.append("facultyid", notesData.facultyId);
    formData.append("level", notesData.level);
    formData.append("subjectid", notesData.subjectId);
    formData.append("material_type", notesData.materialType);
    formData.append("file", notesData.file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
      onUploadProgress: (progressEvent) => {
        percentCompletedValue = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (onProgressChangeCallback) {
          onProgressChangeCallback(percentCompletedValue);
        }
      },
    };

    const response = await axios.put(
      `${API_BASE_URL}/api/material/material/${notesData.id}`,
      formData,
      config
    );

    return response.data;
  }
);

export const deletenotes = createAsyncThunk(
  "notes/delete",
  async (postId, thunkApi) => {
    const config = {
      headers: {
        "auth-token": JSON.parse(localStorage.getItem("token")),
      },
    };

    await axios.delete(
      `${API_BASE_URL}/api/material/material/${postId}`,
      config
    );
    return postId;
  }
);

const initialState = {
  notesData: [],
  loading: false,
  posts: [],
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getnotes.fulfilled, (state, action) => {
        state.loading = false;
        state.notesData = action.payload;
      })
      .addCase(getnotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(postnotes.fulfilled, (state, action) => {
        state.posts.push(action.payload.data);
        state.notesData.push(action.payload.data);
      })
      .addCase(updatenotes.fulfilled, (state, action) => {
        const updatedPost = action.payload.data;
        const index = state.notesData.findIndex(
          (notes) => notes.id === updatedPost.id
        );
        if (index !== -1) {
          state.notesData[index] = updatedPost;
        } else {
          state.notesData.push(updatedPost);
        }
      })
      .addCase(deletenotes.fulfilled, (state, action) => {
        state.posts = state.posts.filter((post) => post.id !== action.payload);
        state.notesData = state.notesData.filter(
          (notes) => notes.id !== action.payload
        );
      });
  },
});

export default notesSlice.reducer;
