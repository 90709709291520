import { Avatar, Button, Input } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { FaAngleRight, FaArrowLeft } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import ChatContext from "../../../../chatContext/ChatContext";
import { Progress } from "@nextui-org/react";
import chatAdminUser from "../../../../common/utils/AdminData";
import { API_BASE_URL } from "../../../../redux/config";
import axios from "axios";

const UserLoginModal = ({ setOpenUserLoginModal }) => {
  const [activePanel, setActivePanel] = useState("profiles"); // 'profiles', 'guest', 'login'
  const [userName, setUser] = useState("");
  const [error, setError] = useState("");
  const [showChatUsers, setShowChatUsers] = useState(false);
  const {
    guestChatUsers,
    setGuestChatUsers,
    setProfileUserData,
    setguestChatData,
    myChat,
  } = useContext(ChatContext);
  const [progressBar, setProgressBar] = useState(0);

  const cancleBtnClk = () => {
    setOpenUserLoginModal((p) => !p);
    setProgressBar(0);
  };

  const handleProfileClick = (panel) => {
    setActivePanel(panel);
  };

  const handleBackClick = () => {
    setActivePanel("profiles");
    setError("");
    setUser("");
    setShowChatUsers(false);
  };

  const navigate = useNavigate();

  const GuestUserPlaced = (e) => {
    e.preventDefault();
    if (userName.length < 2 || userName.length > 20) {
      setError("Name must be 2-20 characters.");
      return;
    }
    setError("");
    let myId = Date.now();
    setGuestChatUsers((prevState) => ({
      ...prevState,
      guestName: userName,
      guestId: myId,
    }));

    setShowChatUsers(true);
  };

  const AdminChatClick = (e) => {
    const createGuestUser = async (data) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/guestuser/user`,
          data
        );
        if (response?.data?.id && e?.id) {
          localStorage.setItem("userid", response?.data?.id);
          localStorage.setItem("username", response?.data?.name);
          localStorage.setItem("adminid", e?.id);
          const showStartChat = {
            id: -999,
            text: "showinfo",
            date: new Date().toISOString(),
            subData: {},
            type: "sent",
          };

          const updatedChat = [...myChat];
          updatedChat.push(showStartChat);

          setguestChatData(updatedChat);
        }
      } catch (error) {
        console.error(
          "Error creating guest user:",
          error.response ? error.response.data : error.message
        );
      }
    };
    if (Number(guestChatUsers.guestId) > 0) {
      createGuestUser({ name: guestChatUsers.guestName });
    }
    setGuestChatUsers((prevState) => ({
      ...prevState,
      adminName: e.name,
      adminId: e.id,
      adminProfile: e.profile,
    }));
    setProfileUserData((prevState) => ({
      ...prevState,
      name: e.name,
      image: e.profile,
      id: e.id,
    }));
  };
  useEffect(() => {
    if (guestChatUsers?.adminId) {
      setTimeout(() => {
        setProgressBar(30);
      }, 200);
      setTimeout(() => {
        setProgressBar(60);
      }, 400);
      setTimeout(() => {
        setProgressBar(80);
      }, 600);
      setTimeout(() => {
        setProgressBar(99);
      }, 1000);
      setTimeout(() => {
        setProgressBar(100);
      }, 1500);
    }
  }, [guestChatUsers]);

  useEffect(() => {
    if (progressBar === 100) {
      cancleBtnClk();
    }
    // eslint-disable-next-line
  }, [progressBar]);

  return (
    <div
      onClick={cancleBtnClk}
      className="h-full w-full bg-[#000000be] absolute inset-0 z-[900] flex justify-center items-center"
    >
      <div
        className={`relative bg-[#f0f8ff] overflow-hidden ${
          !showChatUsers ? "p-10" : "py-10 px-5"
        }  rounded shadow-lg w-[80%] max-w-md`}
        onClick={(e) => e.stopPropagation()} // Prevent the modal from closing when clicking inside this div
      >
        <div onClick={cancleBtnClk} className="absolute top-3 right-3 ">
          <RxCross2 className="text-xl text-black/70 hover:scale-110 duration-150 cursor-pointer hover:text-black" />
        </div>
        <div className="h-[150px] w-[150px] bg-[#0056a683] rounded-full absolute top-[-100px] left-[-100px]"></div>
        {guestChatUsers?.adminId ? (
          <div>
            <div className="text-[#2B0282] mb-2 text-lg">
              Connecting user...
            </div>
            <Progress color="primary" size="md" value={progressBar} />
          </div>
        ) : (
          <>
            {!showChatUsers ? (
              <form
                className="text-center flex z-20 flex-col gap-4"
                onSubmit={
                  activePanel === "guest"
                    ? GuestUserPlaced
                    : (e) => e.preventDefault()
                }
              >
                {activePanel !== "profiles" ? (
                  <h2
                    onClick={handleBackClick}
                    className="font-semibold flex hover:tracking-wide hover:gap-1.5 duration-100 justify-start items-center gap-1 cursor-pointer text-center text-lg text-[#0056a6] -mt-1"
                  >
                    <FaArrowLeft />{" "}
                    {activePanel === "guest" ? "Guest name" : "Login"}
                  </h2>
                ) : (
                  <h2 className="font-semibold flex hover:gap-1.5 duration-100 justify-center items-center gap-1 text-center text-lg text-[#0056a6]">
                    Chat with
                  </h2>
                )}
                <div
                  className={`flex w-full duration-150 relative ${
                    activePanel === "profiles"
                      ? "h-[90px]"
                      : activePanel === "guest"
                      ? "h-[100px]"
                      : "h-[60px]"
                  }`}
                >
                  <div
                    className={`absolute transition-transform duration-500 flex w-full justify-around items-center ${
                      activePanel !== "profiles"
                        ? "-translate-x-full opacity-0"
                        : "translate-x-0 opacity-100"
                    }`}
                  >
                    <div
                      className="flex transition-transform hover:scale-105 duration-500 group justify-center cursor-pointer items-center flex-col"
                      onClick={() => handleProfileClick("guest")}
                    >
                      <Avatar
                        className="text-black/50"
                        size="lg"
                        showFallback
                        src="https://images.unsplash.com/broken"
                      />
                      <div className="text-sm mt-0.5 ml-1 cursor-pointer group-hover:tracking-wide duration-100 text-center font-medium text-[#2B0282] flex justify-center items-center">
                        Guest
                        <FaAngleRight className="mt-0.5" />
                      </div>
                    </div>

                    <div
                      className="flex transition-transform hover:scale-105 duration-500 group justify-center cursor-pointer items-center flex-col"
                      onClick={() => handleProfileClick("login")}
                    >
                      <Avatar
                        className="text-black/50"
                        size="lg"
                        showFallback
                        src="https://images.unsplash.com/broken"
                      />
                      <div className="text-sm mt-0.5 ml-1 cursor-pointer group-hover:tracking-wide duration-100 text-center font-medium text-[#2B0282] flex justify-center items-center">
                        Login
                        <FaAngleRight className="mt-0.5" />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`absolute transition-transform duration-500 flex w-full justify-center items-center flex-col ${
                      activePanel === "guest"
                        ? "translate-x-0 opacity-100"
                        : "translate-x-full opacity-0"
                    }`}
                  >
                    {activePanel === "guest" && (
                      <>
                        <Input
                          placeholder=""
                          label="Enter Your name"
                          color="primary"
                          size="sm"
                          variant="bordered"
                          className="w-full"
                          value={userName}
                          onChange={(e) => setUser(e.target.value)}
                        />
                        {error && (
                          <p className="text-xs text-danger text-left ml-2 w-full">
                            {error}
                          </p>
                        )}
                        <Button
                          radius="sm"
                          variant="ghost"
                          color="primary"
                          endContent={<FaAngleRight className="mt-0.5" />}
                          className="hover:tracking-wider duration-150 w-full mt-2"
                          type="submit"
                        >
                          Start Chat
                        </Button>
                      </>
                    )}
                  </div>

                  <div
                    className={`absolute transition-transform duration-500 flex w-full justify-center items-center flex-col ${
                      activePanel === "login"
                        ? "translate-x-0 opacity-100"
                        : "translate-x-full opacity-0"
                    }`}
                  >
                    {activePanel === "login" && (
                      <Button
                        radius="sm"
                        variant="ghost"
                        color="primary"
                        endContent={<FaAngleRight className="mt-0.5" />}
                        className="hover:tracking-wider duration-150 w-full mt-2"
                        onClick={() => navigate("/login")}
                      >
                        Go login page
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            ) : (
              <div className="">
                <h2
                  onClick={() => setShowChatUsers(false)}
                  className="font-semibold flex hover:gap-1.5 hover:tracking-wide cursor-pointer duration-100 justify-center mb-3 items-center gap-1 text-left text-lg text-[#0056a6]"
                >
                  <FaArrowLeft /> Select Person
                </h2>
                <div
                  id="scrollableDivDashboard"
                  className="max-h-[270px] overflow-y-auto overflow-x-hidden py-2 px-2 grid gap-y-4 gap-2  grid-cols-[repeat(auto-fit,minmax(55px,1fr))]"
                >
                  {chatAdminUser.map((e) => {
                    return (
                      <div
                        key={e.id}
                        className="flex transition-transform hover:scale-105 duration-75 group justify-center cursor-pointer items-center flex-col"
                        onClick={() => AdminChatClick(e)}
                      >
                        <Avatar
                          className="text-black/50"
                          size="lg"
                          showFallback
                          src={e.profile}
                        />
                        <div className="text-xs line-clamp-1 mt-0.5 ml-1 cursor-pointer group-hover:tracking-wide duration-100 text-center font-medium text-[#2B0282] flex justify-center items-center">
                          {e.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserLoginModal;
