import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import EachChat from "./EachChat";
import { Avatar, Spinner } from "@nextui-org/react";
import { useLocation, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../../redux/config";
import axios from "axios";
import ChatContext from "../../../../../chatContext/ChatContext";

const Messages = ({
  chatProfile,
  data,
  loading,
  lastMsg,
  recomended,
  setMyChat,
  setRecomended,
  isLoading,
  setIsLoading,
  lastMessage,
  showLastMessage,
  setShowLastMessage,
  getRecomendedData,
  getAnswerData,
  isDashboard,
  setOpenUserLoginModal,
}) => {
  const [finalData, setFinalData] = useState([]);
  const chatContainerRef = useRef(null);
  const location = useLocation();
  const prevDataLengthRef = useRef(0);
  const [showingData, setShowingData] = useState([]);
  const {
    setguestChatData,
    guestChatData,
    setParamPage,
    profileUserData,
    typingData,
  } = useContext(ChatContext);

  useEffect(() => {
    if (guestChatData.length > 0) {
      if (
        typingData.status &&
        String(typingData.senderId) === String(profileUserData.id)
      ) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
  }, [typingData, profileUserData, setIsLoading, guestChatData.length]);

  const params = useParams().id;
  useEffect(() => {
    if (Number(params) > 0) {
      setParamPage(params);
    }
  }, [params, setParamPage]);

  const fetchEachUserChat = useCallback(
    async (data) => {
      try {
        const config = {};
        const response = await axios.post(
          `${API_BASE_URL}/api/messages/message/geteach`,
          data,
          config
        );
        const returnedData = response?.data || [];

        // Add the 'type' property to each message
        const addedColumnData = returnedData.map((e) => ({
          ...e,
          type:
            String(e.userId) === String(localStorage.getItem("userid"))
              ? "sent"
              : "received",
        }));

        if (addedColumnData.length > 0) {
          setguestChatData(() => [...addedColumnData]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    },
    [setguestChatData]
  );

  useEffect(() => {
    const userId = Number(localStorage.getItem("userid"));
    const chatUserId =
      Number(params) || Number(localStorage.getItem("adminid"));

    if (userId > 0 && chatUserId > 0) {
      fetchEachUserChat({ userId, chatUserId });
    }
  }, [params, fetchEachUserChat]);

  useEffect(() => {
    if (data) {
      const updatedData = data.map((item) => ({
        ...item,
      }));
      setFinalData(updatedData);
    }
  }, [data]);

  const scrollToBottom = useCallback(() => {
    chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight -
      chatContainerRef.current.clientHeight;
    prevDataLengthRef.current = finalData.length;
  }, [finalData.length]);

  useEffect(() => {
    if (
      chatContainerRef.current &&
      prevDataLengthRef.current !== finalData.length
    ) {
      scrollToBottom();
    }
  }, [location, scrollToBottom, finalData.length]);

  useEffect(() => {
    scrollToBottom();
  }, [location, lastMsg?.id, scrollToBottom]);

  useEffect(() => {
    const resetMessages = async () => {
      const initialMessages = [];
      prevDataLengthRef.current = initialMessages.messages
        ? initialMessages.messages.length
        : 0;
    };
    resetMessages();
  }, [location]);

  useEffect(() => {
    if (data.length > 0) {
      const sortedData = data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      const uniqueIds = new Set();
      const filteredData = sortedData.filter((item) => {
        if (!uniqueIds.has(item?.id)) {
          uniqueIds.add(item?.id);
          return true;
        }
        return false;
      });

      setShowingData(filteredData);
    }
  }, [data]);

  useEffect(() => {
    if (showingData[0]?.type === "received") {
      if (showingData[0].isread) return;
    }
  }, [showingData]);

  const recomendedModalClick = async (e) => {
    setMyChat((p) => [
      {
        ...e,
        type: "sent",
        image: "",
        date: new Date().toISOString(),
        subData: {},
      },
      ...p,
    ]);
    setRecomended([]);
    setIsLoading(true);

    const delay = new Promise((resolve) => setTimeout(resolve, 500));
    const ansDataPromise = getAnswerData(e?.id);
    const recomendedDataPromise = getRecomendedData(e?.id);
    const [ansData] = await Promise.all([ansDataPromise, delay]);
    if (!ansData?.text) {
      setShowLastMessage(true);
      setIsLoading(false);
      return;
    }
    if (e?.text) {
      setMyChat((p) => [
        { ...ansData, type: "received", date: new Date().toISOString() },
        ...p,
      ]);
    } else {
      setShowLastMessage(true);
    }
    try {
      const [data] = await Promise.all([recomendedDataPromise, delay]);
      setRecomended(data || []);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showLastMessage) {
      if (Number(data[0]?.id) !== 999999999999) {
        setMyChat((p) => [
          {
            ...lastMessage,
            type: "received",
            date: new Date().toISOString(),
            subData: {},
          },
          ...p,
        ]);
      }
    }
    // eslint-disable-next-line
  }, [showLastMessage, setMyChat]);

  return (
    <div
      className={`h-full ${
        isDashboard ? "bg-[#fff]" : "bg-[#f0f8ff]"
      }  z-[100] relative  flex flex-col overflow-hidden`}
    >
      <div
        id={!isDashboard ? `scrollableDiv` : "scrollableDivDashboard"}
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
          padding: "10px 8px",
        }}
        ref={chatContainerRef}
        className={`${isLoading ? "!pb-14" : ""}`}
      >
        {!showingData[0] && (
          <>{loading && <Spinner color="primary" className="mt-1" />}</>
        )}

        {isLoading && (
          <div className="flex absolute bottom-2 z-50 mb-3">
            <div className="rounded-full absolute left-0 flex justify-center items-end">
              <Avatar
                src={profileUserData.image || chatProfile}
                showFallback
                size="sm"
                classNames={{
                  icon: "text-black/50",
                }}
              />
            </div>
            <div
              className={`font-poppins ml-10 shadow-sm z-10 relative text-xs sm:text-sm flex max-w-[50%] px-5 h-[33px] rounded-md items-center justify-center rounded-bl-none bg-[#fbfcf8] text-[#333333]"
              `}
            >
              <div className="typing-dots text-[#0056A6] pt-0.5">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        )}
        {recomended.length > 0 && (
          <div className="w-[80%] mx-auto h-full pt-6 flex justify-center items-center flex-wrap gap-2">
            {recomended.map((e, index) => {
              return (
                <div
                  key={index}
                  className="px-4 pb-1.5 py-1 text-sm rounded-full bg-[#0056A6] hover:bg-[#5238b1] cursor-pointer duration-400 hover:scale-105 text-[#f0f8ff]"
                  onClick={() => recomendedModalClick(e)}
                >
                  {e.text}
                </div>
              );
            })}
          </div>
        )}

        {data.map((message, index) => {
          const isFirstReceivedMessage =
            message.type === "received" &&
            (index === 0 || showingData[index - 1]?.type !== "received");

          const shouldShowAvatar = message?.images
            ? message.type === "received" &&
              (isFirstReceivedMessage ||
                JSON.parse(message?.images || [])?.length > 0)
            : message.type === "received" && isFirstReceivedMessage;

          return (
            <div key={index}>
              <EachChat
                chatProfile={chatProfile}
                message={message}
                shouldShowAvatar={shouldShowAvatar}
                allMessages={showingData}
                setOpenUserLoginModal={setOpenUserLoginModal}
                profileUserData={profileUserData}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Messages;
