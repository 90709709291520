// store.js

import { configureStore } from "@reduxjs/toolkit";
import facultyReducer from "./slices/facultySlice";
import subjectReducer from "./slices/subjectSlice";
import newsReducer from "./slices/newsSlice";
import notesReducer from "./slices/notesSlice";
import feedbackReducer from "./slices/feedbackSlice";
import messageReducer from "./slices/messageSlice";
import authReducer from "./slices/authSlice";

import statisticsReducer from "./slices/statisticsSlice";

export default function makeStore() {
  return configureStore({
    reducer: {
      facultyReducer,
      subjectReducer,
      newsReducer,
      notesReducer,
      feedbackReducer,
      authReducer,
      messageReducer,

      statisticsReducer,
    },
  });
}

export const store = makeStore();
