import React from "react";
import { Route, Routes } from "react-router-dom";
import ChatProfile from "./components/ChatProfile";
import Login from "./components/Login/Login";

const Client = () => {
  return (
    <>
      <div className={`ml-0 duration-200 py-0 px-0 bg-white !text-black`}>
        <Routes>
          <Route element={<ChatProfile />} path="*" />
          <Route element={<Login />} path="/login" />
        </Routes>
      </div>
    </>
  );
};

export default Client;
